.member-grid {
  display: grid;
  // grid-template-columns: 29.84% 25.39% 20.63% 12.06% 40px;
  grid-template-columns: 20% 15% 13% 13% 15% 12% 40px;
}

// .configuration-grid {
//   display: grid;
//   grid-template-columns: 35% 15% 15% 15% 40px;
// }

@media (max-width: 991px) {
  .grid-resp-lables.member-grid,
  .grid-resp-lables.configuration-grid {
    display: none;
  }
  .grid-resp.member-grid {
    grid-template-columns: 1fr 1fr;
  }
  .grid-resp.configuration-grid {
    grid-template-columns: auto;
  }
  .grid-resp.member-grid .data-label,
  .grid-resp.configuration-grid .data-label {
    display: grid;
    gap: 0.5rem;
  }
  .grid-resp.member-grid .data-label::before,
  .grid-resp.configuration-grid .data-label::before {
    content: attr(data-label);
    font-weight: 700;
  }
}
@media (max-width: 767px) {
  .grid-resp-lables {
    display: none;
  }
  .grid-resp,
  .grid-resp.member-grid {
    grid-template-columns: auto;
  }
  .grid-resp .data-label {
    display: grid;
    gap: 0.5rem;
  }
  .grid-resp .data-label::before {
    content: attr(data-label);
    font-weight: 700;
  }
}
