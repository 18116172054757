.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-overlay.visible {
  display: flex;
}

.overlay-content {
  text-align: center;
  color: white;
}

/* Add a class to disable scrolling */
.disable-scroll {
  overflow: hidden;
}