.percentCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .percent {
    position: relative;

    svg {
      position: relative;
      width: 80px; /* Adjusted width */
      height: 80px; /* Adjusted height */
      transform: rotate(-90deg);

      circle {
        width: 100%;
        height: 100%;
        fill: none;
        stroke: var(--green-100);
        stroke-width: 5;
        stroke-linecap: round;

        &:last-of-type {
          stroke-dasharray: 251px; /* Circumference of 80px circle */
          stroke-dashoffset: calc(251px - (251px * var(--percent)) / 100);
          stroke: var(--green-600);
        }
      }
    }
  }

  .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--green-600);
  }

  .title {
    h2 {
      margin: 25px 0 0;
    }
  }
}
