body {
  background: #f6f6f6;
}
.cursor-pointer {
  cursor: pointer;
}
.minHeight {
  min-height: 163px;
}
.button-link {
  color: #1a1a1f;
}
.p-button.button-link:not(:disabled):hover {
  background: transparent;
  box-shadow: none;
}
