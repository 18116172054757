.masterdata-grid {
  display: grid;
  grid-template-columns: 30% 45% 55px 38px;
}

.ballot-approval-criteria-grid {
  display: grid;
  gap: 1rem;
  align-items: end;
}

/* Default layout: 1 column (fallback for very small screens) */
.ballot-approval-criteria-grid {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

/* Small screens: 2 columns */
@media (min-width: 420px) {
  .ballot-approval-criteria-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

/* Medium screens: 3 columns */
@media (min-width: 768px) {
  .ballot-approval-criteria-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

/* Large screens: 4 columns */
@media (min-width: 1024px) {
  .ballot-approval-criteria-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

/* Extra large screens: 7 columns */
@media (min-width: 1200px) {
  .ballot-approval-criteria-grid {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

@media (max-width: 767px) {
  .grid-resp-lables {
    display: none;
  }
  .grid-resp,
  .grid-resp.masterdata-grid {
    grid-template-columns: auto;
  }
  .grid-resp .data-label {
    display: grid;
    gap: 0.5rem;
  }
  .grid-resp .data-label::before {
    content: attr(data-label);
    font-weight: 700;
  }
}
