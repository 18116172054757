.add-details-grid {
  display: grid;
  grid-template-columns: 12% 12% 20% 30% auto;
}
.read-only {
   background-color: #E6E6E6 !important;
}
.hide-input-label label {
  display: none !important;
}
@media (max-width: 991px) {
  .add-details-grid {
    grid-template-columns: 15% 15% 20% 30% auto;
  }
}
@media (max-width: 767px) {
  .add-details-grid-lables {
    display: none;
  }
  .add-details-grid {
    grid-template-columns: auto;
  }
  .add-details-grid .data-label {
    display: grid;
    gap: 0.5rem;
  }
  .add-details-grid .data-label::before {
    content: attr(data-label);
    font-weight: 700;
  }
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 }

